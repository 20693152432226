/* this file is transformed by vux-loader */
/* eslint-disable */
import FoolterNav from "./FoolterNav";
import { sessionStorage } from "../../../assets/myjs/glovar";
export default {
  components: {
    FoolterNav
  },
  data() {
    return {
      selected: 0
    };
  },
  mounted() {},
  methods: {
    onChange(index) {
      console.log("外部-切换到tabbar：" + index);
      this.tabBars[index].isShowRedDot = this.tabBars[index].isShowRedDot ? false : false;
    }
  },
  created() {}
};