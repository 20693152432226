/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import { sessionStorage } from "../../../assets/myjs/glovar";
// import { Tabbar, TabbarItem } from "vant";

export default {
  // components: {
  //   [Tabbar.name]: Tabbar,
  //   [TabbarItem.name]: TabbarItem,
  // },
  props: {
    // 选中tabbar
    selected: {
      type: Number,
      default: 0
    },
    badgeA: "",
    badgeB: "",
    badgeC: ""
  },
  data() {
    return {
      currentSelected: this.selected,
      //tabbar格式
      tabBars: [{
        name: "/ywProject",
        badge: "",
        text: "业务项目",
        icon: "orders-o"
      }, {
        name: "/ywTrack",
        badge: "",
        text: "业务跟踪",
        icon: "exchange"
      }, {
        name: "/ywNote",
        badge: "",
        text: "记录批注",
        icon: "comment-o"
      }]
    };
  },
  methods: {
    onBadge() {
      var url = _baseUrl + "/mobile/ywgz/getYwGzCount.do";
      axios.post(url).then(res => {
        this.tabBars[0].badge = res.data.ywxxTotal;
        this.tabBars[1].badge = res.data.ywGzXxTotal;
        this.tabBars[2].badge = res.data.ywGzPzXxTotal;
      });
    }
  },
  // 初始化页面选中状态
  created() {
    /*
      由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
      console.log(this.$route); //打印当前路由属性
      console.log(this.tabBars);
      console.log(this.$props.selected);
      console.log(this.currentSelected);
      this.$router.push("/Module1");
      */
    if (this.$route.path === "/yewhome" && this.tabBars.length) {
      this.$router.push(this.tabBars[this.currentSelected].name);
    }
    this.onBadge();
  },
  mounted() {}
};